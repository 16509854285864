import { toInteger } from "lodash"
import { ALL, STATUTE_CONSOLIDATED } from "@/config/material-type"

/**
 * Add different actions to document object
 * @param {*} router VueRouter
 * @param {object} document Document object
 * @param {string} uiLanguage
 * @param {bool} listing Are the actions for document listing
 */
function addDocumentActions(router, document, uiLanguage, listing = true) {
  const material = ALL[document.materialType]

  if (!material) {
    throw new Error(`[addDocumentActions] Could not find material configuration for '${document.materialType}'`)
  }

  const configuration = material.configuration.actions
  // Document may have multiple references; use first one to link
  const amendment = configuration.edilexStatuteAmendment
    && document.references
    && document.references.length > 0
    && document.references[0] // This can somehow be null some times
    ? document.references[0].document // TODO: Should use find/filter to find correct material type
    : null
  const amendmentAnchor = configuration.edilexStatuteAmendment && amendment
    ? `muutos${amendment.documentKey}`
    : null
  // Is declared here to avoid being called 3 times per document
  const parliamentRefId = configuration.parliamentReferenceCard
    || configuration.parliamentReferenceCardFiPdf
    || configuration.parliamentReferenceCardSvPdf
    ? `${toInteger(document.documentKey.substring(4))}/${document.year}`
    : null

  document.DocumentActions = [
    ...configuration.edilexReferenceCard ? [
      {
        href: `https://www.edilex.fi/smur/${document.documentKey}`,
        icon: "reference-card",
        label: "documentActions.edilexReferenceCard",
        target: "_blank",
        type: "link",
        variant: "primary",
      },
    ] : [],
    ...configuration.edilexStatuteAmendment ? [
      {
        href: `https://www.edilex.fi/smur/${document.documentKey}/${amendmentAnchor}`,
        icon: "statute-amendment",
        label: "documentActions.edilexStatuteAmendment",
        target: "_blank",
        type: "link",
        variant: "primary",
      },
    ] : [],
    ...configuration.finlexReferenceCard ? [
      {
        href: `https://www.finlex.fi/${document.language}/laki/smur/${document.year}/${document.documentKey}`,
        icon: "reference-card",
        label: "documentActions.finlexReferenceCard",
        target: "_blank",
        type: "link",
        variant: "primary",
      },
    ] : [],
    ...configuration.parliamentReferenceCard ? [
      {
        href: `https://www.eduskunta.fi/valtiopaivaasiat/HE+${parliamentRefId}`,
        icon: "reference-card",
        label: "documentActions.referenceCard",
        target: "_blank",
        type: "link",
        variant: "primary",
      },
    ] : [],
    ...configuration.parliamentReferenceCardFiPdf ? [
      {
        href: `https://www.eduskunta.fi/pdf/HE+${parliamentRefId}`,
        icon: "document-pdf-fi",
        label: "documentActions.document-pdf-fi",
        target: "_blank",
        type: "link",
        variant: "primary",
      },
    ] : [],
    ...configuration.parliamentReferenceCardSvPdf ? [
      {
        href: `https://www.eduskunta.fi/pdf/RP+${parliamentRefId}`,
        icon: "document-pdf-sv",
        label: "documentActions.document-pdf-sv",
        target: "_blank",
        type: "link",
        variant: "primary",
      },
    ] : [],
    ...configuration.languages ? material.languages
      .filter(language => language !== document.language) // Do not include document's own language
      .map((language) => {
        return {
          icon: `lang-${language}`,
          label: `documentActions.lang-${language}`,
          to: {
            name: router.findMaterialViewRoute(document.materialType),
            params: {
              documentLanguage: language !== uiLanguage
                ? language
                : null, // Use UI language by default
              documentKey: document.documentKey,
              language: uiLanguage,
            },
          },
          type: "link",
          variant: "primary",
        }
      }) : [],
    ...configuration.pdf ? [
      {
        icon: "document-pdf",
        label: "documentActions.pdf",
        to: {
          name: router.findMaterialPdfRoute(document.materialType),
          params: {
            documentLanguage: document.language !== uiLanguage
              ? document.language
              : null, // Use UI language by default
            documentKey: document.documentKey,
            language: uiLanguage,
          },
        },
        type: "link",
        variant: "primary",
      },
    ] : [],
    ...configuration.referenceCard ? [
      {
        icon: "reference-card",
        label: "documentActions.referenceCard",
        to: {
          // Find statute consolidated route from router using document view
          // lookup
          name: router.findMaterialViewRoute({ materialType: [STATUTE_CONSOLIDATED.materialType] }),
          params: {
            documentKey: document.documentKey,
            language: uiLanguage,
          },
        },
        type: "link",
        variant: "primary",
      },
    ] : [],
    // Document page only, these should not be shown in listings
    ...configuration.bookmark && !listing ? [] : [], // TODO: Bookmarks
    ...configuration.print && !listing ? [
      {
        action: "print",
        icon: "print",
        label: "documentActions.print",
        type: "button",
        variant: "primary",
      },
    ] : [],
    ...configuration.socialShare && !listing ? [] : [], // TODO: Social media sharing
  ]

  return document
}

function formatHeading(item, routeName) {
  return {
    meta: { title: `${item.key} ${item.label}` },
    name: `${routeName}`,
    type: item.type,
    children: item.children
      ? item.children.map(child => formatHeading(child, routeName))
      : [],
    hash: `#token${item.key}`,
  }
}

export {
  addDocumentActions,
  formatHeading,
}
