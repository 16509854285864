import axios from "axios"

function createInstance(store) {
  // TODO: This is dependent on oidc!
  const accessToken = store.state.oidcStore
    ? store.state.oidcStore.access_token
    : null

  return axios.create({
    baseURL: process.env.VUE_APP_API,
    headers: accessToken
      ? { "Authorization": `Bearer ${accessToken}` }
      : undefined,
    withCredentials: true,
  })
}

export default createInstance
