import { merge } from "lodash"
import defaultConfig from "@common/config/edita.config.js"
import {
  ENVIRONMENT_BULLETIN,
  EU_STATUTE_CONSOLIDATED,
  NEWS_ARTICLE,
  STATUTE_CONSOLIDATED,
} from "@/config/material-type"

const SERVICE_CODE = "headpower"
const CONFIG = merge({}, defaultConfig, {
  serviceCode: SERVICE_CODE,
  auth: {
    useAuthentication: true,
    requireAuthentication: true,
  },
  // Configurations for DS components' content
  // Use Vue component name for easier distinction.
  FrontPage: {
    auth: {
      blocks: [
        {
          type: "latest",
          materialType: [NEWS_ARTICLE.materialType],
          heading: "heading.newsArticle.frontpage",
          count: 5,
          view: "grid",
          orientation: "vertical",
        },
        {
          type: "latest",
          materialType: [ENVIRONMENT_BULLETIN.materialType],
          heading: "heading.environmentBulletin.frontpage",
          count: 6,
          view: "list",
          orientation: "vertical",
        },
      ],
    },
  },
  PageHeader: {
    requireAuthentication: true,
    extraNav: [
      {
        meta: { title: "label.keywords" },
        name: "keywords",
      },
      {
        meta: { title: "label.instructions" },
        name: "module-other-document.documentView",
        navParams: { documentKey: "ohjeet" },
      },
    ],
  },
  PageFooter: {
    requireAuthentication: true,
    PageFooterBranding: {
      url: "https://www.headpower.fi",
      name: "headpower",
      nameFull: "HeadPower Oy",
      target: "_blank",
      coop: true,
    },
    PageFooterLinks: [
      {
        label: "helpdesk@headpower.fi",
        labelPrefix: "label.feedbackPrefix",
        href: "mailto:helpdesk@headpower.fi",
      },
      {
        label: "label.contactInformation",
        href: "https://headpower.fi/tietoa_meista/",
      },
      {
        label: "label.privacyPolicy",
        href: "https://headpower.fi/tietosuojaseloste/",
      },
    ],
    PageFooterOtherServices: [],
  },
  SearchSuggest: [
    {
      type: "keyword",
      listTitle: "label.keyword",
      limit: 5,
      showDocumentCount: true,
    },
    {
      type: "document",
      listTitle: "label.statute",
      materialType: [
        STATUTE_CONSOLIDATED.materialType,
        EU_STATUTE_CONSOLIDATED.materialType,
      ],
      limit: 5,
      showDocumentCount: false,
    },
  ],
})

export default CONFIG
