var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ContentContainer',{attrs:{"variant":"section"}},[_c('SidebarMaterial',{attrs:{"active":_vm.$route.name,"anchor":_vm.$route.hash,"advanced-search-route":_vm.advancedSearchRoute,"has-subsection":_vm.configuration.sidebar.subsection.enabled,"has-search":_vm.configuration.sidebar.search,"subsubsection":_vm.configuration.sidebar.subsection.subitems,"has-update-info":_vm.configuration.sidebar.updateInfo,"items":_vm.section.children,"material-type":_vm.materialType,"options-material-jump-to":_vm.configuration.sidebar.jumpToDocument,"section":_vm.section,"ui-language":_vm.uiLanguage,"update-info":_vm.updateInfo},on:{"material-jump-to":_vm.storeMaterialJumpTo,"search-global":_vm.searchGlobal}}),_c('MainContainer',[_c('Heading',{attrs:{"level":"h1"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.title))+" ")]),(_vm.loading)?_c('Throbber'):_vm._e(),(_vm.error)?_c('FeedbackMessage',{attrs:{"variant":"error"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.error))+" ")]):_vm._e(),(!_vm.loading && !_vm.error)?_c('MainContentContainer',[_c('div',{staticClass:"commonnames-abbreviations-desc"},[_vm._v(" "+_vm._s(_vm.$t(`description.abbreviations.${_vm.materialType}`))+" ")]),_c('dl',{staticClass:"commonnames-abbreviations-list"},_vm._l((_vm.items),function(item){return _c('div',{key:item.label},[_c('dt',[_c('abbr',[_vm._v(" "+_vm._s(item.label)+" ")])]),_c('dd',[_vm._v(" "+_vm._s(item.document.DocumentTitle)+" "),_c('BaseLink',{attrs:{"to":{
                name: item.document.routes.view,
                params: {
                  language: item.document.language,
                  documentKey: item.document.documentKey,
                }
              }}},[_vm._v(" "+_vm._s(item.document.DocumentCaption)+" ")])],1)])}),0)]):_vm._e(),(_vm.showBacktotop)?_c('Backtotop',{on:{"click":_vm.goBacktotop}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }