<template lang="html">
  <ContentContainer variant="section">
    <SidebarDescription :section="$route" />
    <MainContainer>
      <Heading level="h1">
        {{ $t("label.feedback") }}
      </Heading>

      <BaseForm
        :disabled="submitted || submitting"
        @submit="submit"
      >
        <BaseInput
          id="name"
          v-model="data.name"
          :disabled="submitted || submitting"
          :errors="dataErrors.name"
          label="label.feedback.name"
        />
        <BaseInput
          id="email"
          v-model="data.email"
          :disabled="submitted || submitting"
          :errors="dataErrors.email"
          label="label.feedback.email"
          type="email"
        />
        <BaseTextarea
          id="message"
          v-model="data.message"
          :disabled="submitted || submitting"
          :errors="dataErrors.message"
          label="label.feedback.message"
        />
        <FeedbackMessage
          v-if="error"
          variant="error"
        >
          {{ $t("error.feedback") }}
        </FeedbackMessage>
        <BaseInput
          id="submit"
          :disabled="submitted || submitting"
          :value="$t('label.feedback.submit')"
          type="submit"
        />
      </BaseForm>
    </MainContainer>
  </ContentContainer>
</template>

<script lang="javascript">
import { mapActions } from "vuex"
import storeTypes from "@common/config/store-types"

export default {
  name: "FeedbackWrapper",
  props: {},
  data() {
    return {
      data: {
        name: "",
        email: "",
        message: "",
      },
      dataErrors: {
        name: [],
        email: [],
        message: [],
      },
      error: false,
      submitted: false,
      submitting: false,
    }
  },
  computed: {},
  methods: {
    ...mapActions("feedback", { storeSendFeedback: storeTypes.SEND_FEEDBACK }),
    submit() {
      this.error = false
      if (this.submitting || !this.validate()) {
        return
      }

      this.storeSendFeedback(this.data)
        .then(() => {
          this.submitted = true
        })
        .catch((error) => {
          this.error = error
        })
        .then(() => {
          this.submitting = false
        })
    },
    validate() {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      let valid = true

      for (const key in Object.keys(this.dataErrors)) {
        this.$set(this.dataErrors, key, [])
      }

      if (this.data.name.trim().length === 0) {
        valid = false
        this.dataErrors.name.push("error.inputEmpty")
      }
      if (this.data.email.trim().length === 0) {
        valid = false
        this.dataErrors.email.push("error.inputEmpty")
      } else if (!re.test(this.data.email.toLowerCase())) {
        valid = false
        this.dataErrors.email.push("error.email.invalid")
      }
      if (this.data.message.length > 5120) {
        valid = false
        this.dataErrors.message.push("error.email.messageTooLong")
      }

      return valid
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
