<template>
  <div />
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "OidcCallback",
  mounted() {
    this.oidcSignInCallback()
      .then(() => {
        const attemptedPage = window.localStorage.getItem("oidc.previousPage")

        if (attemptedPage) {
          window.localStorage.removeItem("oidc.previousPage")
          this.$router.push(attemptedPage)
        } else {
          this.$router.push("/")
        }
      })
      .catch((error) => {
        if (this.error && process.env.VUE_APP_ENV !== "production"
          && process.env.NODE_ENV !== "staging"
        ) {
          console.error("Error OidcCallback:", error)
        }
        // this.$router.push("/oidc-callback-error") // Handle errors any way you want
      })
  },
  methods: { ...mapActions(["oidcSignInCallback"]) },
}
</script>
