import { subject, updatedInfo } from "@common/repository"
import TYPES from "@common/config/store-types"
import Vue from "vue"

/**
 * This module is meant for application content fetched from Api only once when
 * application is loaded (usually during whoami [router middleware]).
 */
const module = {
  namespaced: true,
  state: {
    fetched: false,
    fetching: false,
    subjects: {},
    updatedInfos: [],
  },
  getters: {
    getUpdatedInfo: state => (materialType, language) => {
      const materialInfo = state.updatedInfos.find(info =>
        info.materialType === materialType && info.language === language
      )

      return materialInfo ? materialInfo.content : ""
    },
  },
  mutations: {
    setFetched(state) {
      state.fetched = true
    },
    setFetching(state, fetching) {
      state.fetching = fetching
    },
    [TYPES.SET_SUBJECTS](state, { language, subjects }) {
      const newState = {
        ...state.subjects,
        [language]: subjects,
      }

      Vue.set(state, "subjects", newState)
    },
    [TYPES.SET_UPDATED_INFO](state, payload) {
      Vue.set(state, "updatedInfos", [...payload])
    },
  },
  actions: {
    [TYPES.FETCH_GLOBALLY_REQUIRED_INFORMATION]({ commit, dispatch, state }) {
      if (state.fetched) {
        if (process.env.APPLICATION_ENV !== "production") {
          return Promise.reject(new Error("[STATIC_CONTENT_WARNING] Already fetched data (not shown in prod)"))
        } else {
          return
        }
      } else if (state.fetching) {
        if (process.env.APPLICATION_ENV !== "production") {
          return Promise.reject(new Error("[STATIC_CONTENT_WARNING] Already fetching data (not shown in prod)"))
        } else {
          return
        }
      } else {
        commit("setFetching", true)

        return Promise.all([
          dispatch(TYPES.GET_SUBJECTS),
          dispatch(TYPES.GET_UPDATED_INFO),
        ]).finally(() => {
          commit("setFetched", true)
          commit("setFetching", false)
        })
      }
    },
    [TYPES.GET_SUBJECTS]({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        if (state.subjects[rootState.language]) {
          resolve(state.subjects[rootState.language])
        }

        // TODO: Handle fetching all UI languages
        return subject.getSubjects({ language: rootState.language })
          .then((subjects) => {
            commit(TYPES.SET_SUBJECTS, {
              language: rootState.language,
              subjects,
            })

            return resolve(subjects)
          })
          .catch((error) => {
            commit(TYPES.SET_SUBJECTS, {})

            return reject(error)
          })
      })
        .catch((error) => {
          if (error && process.env.VUE_APP_ENV !== "production"
            && process.env.NODE_ENV !== "staging"
          ) {
            // eslint-disable-next-line no-console
            console.error("[store:dispatch:subject]", error)
          }
        })
    },
    [TYPES.GET_UPDATED_INFO]({ commit }) {
      return updatedInfo.getAll()
        .then((infos) => {
          commit(TYPES.SET_UPDATED_INFO, infos)

          return infos
        })
        .catch((error) => {
          commit(TYPES.SET_UPDATED_INFO, [])
          if (error && process.env.VUE_APP_ENV !== "production"
            && process.env.NODE_ENV !== "staging"
          ) {
            // eslint-disable-next-line no-console
            console.error("[store:dispatch:updatedInfo]", error)
          }
        })
    },
  },
}

export default module
