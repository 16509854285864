import { auth as api } from "@common/repository"
import TYPES from "@common/config/store-types"
import editaConfig from "@/edita.config.js"

const module = {
  namespaced: true,
  state: {
    error: null,
    loggedIn: false,
    loginRedirectRoute: null, // Store which route user tried to access if not logged in
    userDisplayName: null,
    userType: null,
    whoamiDone: false,
  },
  mutations: {
    login(state, payload) {
      state.loggedIn = true
      state.userDisplayName = payload.displayName
      state.userType = payload.userType
      state.whoamiDone = true // We can assume whoami as done since we get details from login request
    },
    logout(state) {
      state.loggedIn = false
      state.userDisplayName = null
      state.userType = null
      state.error = null
    },
    setError(state, error) {
      state.error = error
    },
    setLoginRedirect(state, route) {
      state.loginRedirectRoute = route
    },
    whoamiDone(state) {
      state.whoamiDone = true
    },
  },
  actions: {
    [TYPES.EDILEX_AUTHENTICATE]({ commit, dispatch }, payload) {
      commit("setError", null) // Clear previous error

      return api.edilexAuthenticate(payload)
        .then((result) => {
          if (result.status === 200) {
            // OK! Logged in
            commit("login", {
              displayName: result.data.displayName,
              userType: result.data.userType,
            })

            // After logging in, get global information as they most likely need
            // to be authenticated for.
            dispatch(`static/${TYPES.FETCH_GLOBALLY_REQUIRED_INFORMATION}`, {}, { root: true })

            // Also send user to page they attempted to view or to frontpage
            dispatch(TYPES.GO_LOGIN_REDIRECT)
          }
        })
        .catch((error) => {
          // See: https://github.com/axios/axios#handling-errors
          if (error.response) {
            commit("setError", error.response.status)
          } else if (error.request) {
            commit("setError", true)
          } else {
            commit("setError", true)
          }
        })
    },
    [TYPES.EDILEX_SIGNOUT]({ commit, rootGetters, rootState }) {
      return api.edilexLogout()
        .then((result) => {
          if (result.status === 200) {
            commit("logout")
            rootGetters.$router.push(editaConfig.auth.requireAuthentication
              ? {
                name: "login",
                params: { language: rootState.language },
              }
              : {
                name: "home",
                params: { language: rootState.language },
              })
          }
        })
    },
    [TYPES.GO_LOGIN_REDIRECT]({ dispatch, state, rootGetters, rootState }) {
      const homeRoute = {
        name: "home",
        params: { language: rootState.language },
      }

      rootGetters.$router.push(state.loginRedirectRoute
        ? state.loginRedirectRoute
        : homeRoute
      )
      // Redirect route can now be removed
      dispatch(TYPES.SET_LOGIN_REDIRECT, homeRoute)
    },
    [TYPES.SET_LOGIN_REDIRECT]({ commit }, route) {
      commit("setLoginRedirect", route)
    },
    [TYPES.WEBSHOP_AUTHENTICATE]({ commit, dispatch }, payload) {
      commit("setError", null) // Clear previous error

      return api.webshopAuthenticate(payload)
        .then((result) => {
          if (result.status === 200) {
            // OK! Logged in
            commit("login", {
              displayName: result.data.displayName,
              userType: result.data.userType,
            })

            // After logging in, get global information as they most likely need
            // to be authenticated for.
            dispatch(`static/${TYPES.FETCH_GLOBALLY_REQUIRED_INFORMATION}`, {}, { root: true })

            // Also send user to page they attempted to view or to frontpage
            dispatch(TYPES.GO_LOGIN_REDIRECT)
          }
        })
        .catch((error) => {
          // See: https://github.com/axios/axios#handling-errors
          if (error.response) {
            commit("setError", error.response.status)
          } else if (error.request) {
            commit("setError", true)
          } else {
            commit("setError", true)
          }
        })
    },
    [TYPES.WEBSHOP_SIGNOUT]({ commit, rootGetters, rootState }) {
      return api.webshopLogout()
        .then((result) => {
          if (result.status === 200) {
            commit("logout")
            rootGetters.$router.push(editaConfig.auth.requireAuthentication
              ? {
                name: "login",
                params: { language: rootState.language },
              }
              : {
                name: "home",
                params: { language: rootState.language },
              })
          }
        })
    },
    [TYPES.WHOAMI]({ commit, dispatch }) {
      return api.whoami()
        .then((result) => {
          if (result.status === 200) {
            // OK! Logged in
            commit("login", {
              displayName: result.data.displayName,
              userType: result.data.userType,
            })

            // After logging in, get global information as they most likely need
            // to be authenticated for.
            dispatch(`static/${TYPES.FETCH_GLOBALLY_REQUIRED_INFORMATION}`, {}, { root: true })
          }
        })
        .catch((error) => {
          commit("whoamiDone") // Commit whoami to state; no whoami loop

          throw error
        })
    },
  },
}

export default module
