
// Error messages in the service
export default {
  fi: {
    "error.code.401": "Et ole kirjautunut palveluun",
    "error.code.403": "Sinulla ei ole riittäviä oikeuksia",
    "error.code.404": "Resurssia ei löytynyt",
    "error.code.503": "Palvelussa on ongelmatilanne. Jos ongelma toistuu niin ota yhteys asiakaspalveluun.",
    "error.code.unknown": "Geneerinen virhe",
    "error.DocumentTocJumpTo.emptyAnchor": "Täytä ainakin yksi kenttä",
    "error.DocumentTocJumpTo.noAnchor": "Kohtaa ei löytynyt",
    "error.email.invalid": "Sähköposti ei ole sallittu",
    "error.email.messageTooLong": "Viesti on liian pitkä",
    "error.emptyInput": "Täytä ainakin yksi hakusana-kenttä",
    "error.feedback": "Palautteen lähetyksessä tapahtui virhe",
    "error.formHasErrors": "Lomakkeessa on virheitä. Ole hyvä ja korjaa ne ennen tallentamista.",
    "error.formJumpToHasErrors": "Lomakkeessa on virheitä, siksi dokumenttiin ei voi siirtyä",
    "error.formLoginHasErrors": "Kirjautumisessa on ongelma. Ole hyvä ja syötä käyttäjätunnus ja salasana uudestaan.",
    "error.formSearchHasErrors": "Hakuehdoissa on virheitä. Korjaa virheet ja hae uudestaan.",
    "error.general": "Palvelussa on ongelmatilanne. Jos ongelma toistuu niin ota yhteys asiakaspalveluun.",
    "error.inputEmpty": "Tyhjä syöte",
    "error.noDocuments": "Ei dokumentteja",
    "error.noDocumentsDefined": "Dokumentteja ei ole määritelty tähän listaukseen sisällysluettelossa (TOC).",
    "error.noKeywords": "Ei asiasanoja",
    "error.notValidYear": "Virheellinen vuosi",
    "error.requiredInput": "Pakollinen tieto puuttuu",
    "error.requiredFieldset": "Täytä ainakin yksi kenttä",
    "error.unknownUsernameOrPassword": "Käyttäjätunnusta tai salasanaa ei löytynyt",
  },
  sv: {
    "error.code.401": "Ni är inte inloggade till tjänsten",
    "error.code.403": "Ni saknar tillräckliga rättigheter",
    "error.code.404": "Resurssia ei löytynyt",
    "error.code.503": "Problem i tjänsten. Ifall problemet upprepar sig, ta kontakt med kundservicen.",
    "error.code.unknown": "Geneerinen virhe",
    "error.DocumentTocJumpTo.emptyAnchor": "Fyll i minst ett fält",
    "error.DocumentTocJumpTo.noAnchor": "Avsnittet hittades inte",
    "error.emptyInput": "Fyll i minst ett sökordsfält",
    "error.formHasErrors": "Det finns fel i formuläret. Korrigera dem innan du sparar.",
    "error.formJumpToHasErrors": "Det finns fel i formuläret: kan inte gå vidare till dokumentet",
    "error.formLoginHasErrors": "Det finns ett problem med att logga in. Ange ditt användarnamn och lösenord igen.",
    "error.formSearchHasErrors": "Det finns fel i sökkriterierna. Korrigera felen och sök igen.",
    "error.general": "Problem i tjänsten. Ifall problemet upprepar sig, ta kontakt med kundservicen.",
    "error.noDocuments": "Inga dokument",
    "error.noDocumentsDefined": "Dokumenten har inte definierats för denna listning i innehållsförteckningen.",
    "error.noKeywords": "Inga referensord",
    "error.notValidYear": "Felaktigt årtal",
    "error.requiredInput": "Obligatorisk information saknas",
    "error.requiredFieldset": "Fyll i minst ett fält",
    "error.unknownUsernameOrPassword": "Användarnamnet eller lösenordet kan inte hittas",
  },
  en: {
    "error.DocumentTocJumpTo.emptyAnchor": "Fill at least one input",
    "error.DocumentTocJumpTo.noAnchor": "Item cannot be found",
    "error.emptyInput": "Fill at least one field",
    "error.formHasErrors": "There are errors in the form. Please correct them before saving.",
    "error.formLoginHasErrors": "There is a problem logging in. Enter your username and password again.",
    "error.formSearchHasErrors": "There are errors in the search criteria. Please correct the errors and search again.",
    "error.noDocuments": "Documents not found",
    "error.noKeywords": "Keywords not found",
    "error.notValidYear": "Invalid year input",
    "error.requiredInput": "Required information is missing",
    "error.requiredFieldset": "Fill in at least one field",
  },
}
