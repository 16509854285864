<script>
import { merge } from "lodash"
import { mapState } from "vuex"
import materialGroups from "@/config/material-search"

export default {
  computed: {
    ...mapState({ uiLanguage: "language" }),
    advancedSearchRoute() {
      return {
        name: "search",
        params: { language: this.uiLanguage },
      }
    },
    /**
     * Material update information displayed in sidebar
     */
    updateInfo() {
      if (this.materialType instanceof Array && this.materialType.length >= 1) {
        const materialType = this.materialType[0] // NOTE: Index 0 should be the "main" route material
        const updateInfo = this.configurations && this.configurations[materialType]
          ? this.configurations[materialType].sidebar.updateInfo
          : null

        return typeof updateInfo === "string"
          ? this.$store.getters["static/getUpdatedInfo"](updateInfo, this.uiLanguage)
          : this.$store.getters["static/getUpdatedInfo"](materialType, this.uiLanguage)
      } else {
        return ""
      }
    },
  },
  methods: {
    // TODO: Material specific search (eg. case law). Currently always does global search.
    searchGlobal({ searchTerm, materialType }) {
      if (!this.materialType && process.env.VUE_APP_ENV !== "production"
        && process.env.NODE_ENV !== "staging"
      ) {
        console.error("[Sidebar:mixin] Missing materialType property!")
      }

      const selectedMaterialGroups = materialGroups
        .filter(materialGroup => materialGroup.materialTypes
          && materialGroup.materialTypes.filter(item => materialType.indexOf(item) >= 0).length > 0)

      // Use same materialList as for listing materials
      // Perform global search query
      this.$router.push(merge({}, this.advancedSearchRoute, {
        query: {
          allWords: searchTerm,
          // TODO: Pass array. Get rid of .join()!
          materialGroups: selectedMaterialGroups.map(materialGroup => materialGroup.id).join(","),
          search: true,
        },
      }))
    },
  },
}
</script>
